footer {
    background-color: #202020;
    color: #FFFFFF85;
    background-image: url(../../img/bg-footer-1.png);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;

    h5 {
        font-size: 30px;
        color: #FFDB0D;
        margin: 10px 0 40px;
    }

    .contact-us-block {
        display: flex;
        padding: 4em 0 2em;
        justify-content: space-between;
        align-items: flex-start;

        .col-1 {
            width: 45%;
            padding-right: 8%;

            .social-media-list {
                margin: 18px 0 18px -24px;
                padding: 0;
                list-style: none;
                display: inline-block;

                li {
                    display: inline-block;
                    margin-left: 24px;

                    a {
                        width: 40px;
                        height: 40px;
                        background: #FFDB0D;
                        display: flex;
                        color: #000;
                        font-size: 24px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;

                        &:hover,
                        &:active,
                        &:focus {
                            background: #fff;
                        }
                    }
                }
            }
        }

        .col-2 {
            width: 27%;

            .menu-footer {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    display: block;
                    margin-bottom: 5px;

                    a {
                        padding: 4px 2px;
                        font-family: 'Oxanium', sans-serif;
                        font-weight: 600;
                        display: inline-block;
                        color: #fff;
                        text-decoration: none;

                        &:hover,
                        &:focus {
                            color: #FFDB0D;
                        }
                    }
                }
            }
        }

        .col-3 {
            width: 27%;

            .footer-address {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    margin-bottom: 18px;
                    display: flex;
                    align-items: flex-start;

                    .footer-icon {
                        color: #FFDB0D;
                        font-size: 18px;
                        width: 40px;
                        margin-top: 8px;
                        margin-left: -5px;
                    }

                    .footer-icon-content {
                        margin-left: 10px;

                        .icon-header {
                            color: #fff;
                        }

                        .icon-descr a {
                            color: #FFFFFF85;

                            &:hover,
                            &:focus {
                                color: #FFDB0D;
                            }
                        }
                    }
                }
            }
        }
    }

    .copyright-block {
        padding: 2em 0 2em;

        p {
            font-size: 14px;
            margin-bottom: 0;
            color: #fff;
            position: relative;
            display: inline-block;

            &::before {
                content: "";
                display: block;
                border: 1px solid #FFDB0D;
                border-radius: 1000px;
                position: absolute;
                top: -26px;
                bottom: -40px;
                right: -100px;
                left: -5000px;
                pointer-events: none;
                opacity: .5;
            }
        }
    }
}

/* Mobile View */
@media only screen and (max-width: 768px) {
    footer .contact-us-block {
        flex-direction: column;
        /* Stack columns vertically */
        padding: 2em 2em 1em !important;
        /* Reduce padding */
        align-items: flex-start;
    }

    footer .contact-us-block .col-1,
    footer .contact-us-block .col-2,
    footer .contact-us-block .col-3 {
        width: auto;
        /* Make columns full width */
        margin-bottom: 30px;
        /* Add spacing between columns */
    }

    footer h5 {
        font-size: 24px;
        /* Reduce heading size */
        margin: 10px 0 30px;
    }

    footer .contact-us-block .social-media-list li a {
        width: 35px;
        /* Slightly reduce icon size */
        height: 35px;
        font-size: 20px;
    }

    footer .contact-us-block .footer-address li {
        flex-direction: row !important;
        /* Stack icon and content */
        align-items: flex-start !important;
        padding-bottom: 1rem !important;
    }

    footer .contact-us-block .footer-address li .footer-icon {
        font-size: 16px;
        /* Reduce icon size */
    }

    footer .contact-us-block .footer-address li .footer-icon-content {
        margin-left: 0;
        /* Reset margin */
    }

    footer .copyright-block {
        padding: 1em 0 1em;
        /* Reduce padding */
    }

    footer .copyright-block p {
        font-size: 12px;
        /* Reduce font size */
    }
}