.products-container {
    padding: 120px 0 100px;
    background: #fff;

    h2 {
        margin-top: 12px;
        text-align: center;
    }

    h6 {
        text-align: center;
    }

    .products-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 70px;
        gap: 20px;

        .products-col {
            width: 23%;
            margin-bottom: 30px;

            img {
                border-radius: 15px;
            }

            h5 {
                text-align: center;
                font-size: 20px;
                margin-top: 10px;
            }
        }
    }
}

/* Mobile View */
@media only screen and (max-width: 768px) {
    .products-container {
        padding: 60px 0 50px;
        /* Reduce padding for mobile */
    }

    .products-container h2 {
        margin-top: 12px;
        text-align: center;
        padding: 2rem 7rem 0;
        /* Reduce padding for mobile */
    }

    .products-container h6 {
        font-size: 20px;
        /* Reduce padding for mobile */
    }

    .products-row {
        margin-top: 40px;
        /* Reduce margin */
        justify-content: center;
        align-items: baseline !important;
        padding: 1rem;
        gap: 12px;
        /* Center the product items */

        .products-col {
            width: 100%;
            /* Full-width columns on mobile */
            margin-bottom: 20px;
            /* Reduce margin between product items */

            img {
                max-width: 100%;
                /* Ensure image scales properly on mobile */
                border-radius: 10px;
                /* Adjust border-radius */
            }

            h5 {
                font-size: 18px;
                /* Slightly reduce font size */
                margin-top: 8px;
                /* Adjust margin */
            }
        }
    }
}