.about-container {
    padding: 10em 0;
    background: #f3f3f3;

    .main-container {
        display: flex;
        justify-content: space-between;
        gap: 70px;

        .about-content {
            width: 50%;

            h6 {
                text-align: left;
                margin: 0;
            }

            p {
                font-size: 18px;
            }
        }

        .about-image-container {
            padding-left: 100px;
            position: relative;

            .experience-text {
                font-family: 'Oxanium', sans-serif;
                position: absolute;
                bottom: -5%;
                left: 0%;
                width: 30%;
                background-color: #FFDB0D;
                padding: 20px 30px 30px;
                font-size: 80px;
                color: #000;
                font-weight: 600;

                span {
                    color: #202020;
                    font-size: 25px;
                    display: block;
                    opacity: 0.7;
                }
            }
        }
    }
}

/* Mobile View */
@media only screen and (max-width: 768px) {
    .about-container {
        padding: 5em 0;
        /* Reduce padding for mobile */
    }

    .main-container {
        flex-direction: column;
        /* Stack the content vertically */
        gap: 5px;
        /* Reduce gap between elements */
        align-items: center;
        /* Center align content */
        width: 100% !important;
    }

    .about-content {
        width: 80% !important;
        /* Full-width on mobile */
        text-align: center;
        /* Center-align text on mobile */

        h6 {
            text-align: center;
            /* Center-align heading */
        }

        p {
            font-size: 16px;
            text-align: justify;
            /* Reduce paragraph font size */
        }
    }

    .about-image-container {
        padding: 1rem;
        margin-left: 1rem;
        padding-left: 0 !important;
        /* Remove padding for mobile */
        width: auto;
        /* Make image container full-width */
        text-align: center;
        /* Center-align the content */

        .experience-text {
            /* Increase width of text box */
            font-size: 30px !important;
            /* Reduce font size */
            padding: 10px 15px 10px !important;
            /* Reduce padding */
            left: 60%;
            /* Center align on mobile */
            transform: translateX(-10%);
            /* Correct centering */

            span {
                font-size: 20px !important;
                /* Reduce size of span text */
            }
        }
    }
}