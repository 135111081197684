.banner-container {
    background: url("../../img/banner-img2.jpg");
    background-attachment: fixed;
    position: relative;
    padding: 11em 0;
    color: #fff;

    h1 {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 50px;
        font-size: 18px;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0%;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        background: #202020;
        zoom: 1;
        filter: alpha(opacity=60);
        -webkit-opacity: 0.6;
        -moz-opacity: 0.6;
        opacity: 0.6;
    }

    .banner-content {
        width: 55%;
        position: relative;
        z-index: 11;
    }
}

/* Mobile View */
@media only screen and (max-width: 768px) {
    .banner-container {
        padding: 15em 0;
        /* Reduce padding for mobile screens */
        background-attachment: scroll;
        /* Disable fixed background on mobile for better performance */
        background-position: 85%;
    }

    .banner-container h1 {
        //font-size: 24px;
        /* Reduce heading size */
        margin-bottom: 15px;
    }

    .banner-container p {
        font-size: 16px;
        /* Reduce paragraph font size */
        margin-bottom: 30px;
    }

    .banner-container .banner-content {
        //width: 90%;
        /* Increase content width to fit mobile screen */
        text-align: center;
        /* Center content for a cleaner mobile view */
        margin: 0 auto;
    }

    .banner-container .banner-content h1 {
        padding-bottom: 2rem;
    }

    .banner-container .banner-content span {
        padding-top: 2rem;
    }
}