.features-container {
    background: #202020;
    padding: 60px 0;

    .features-row {
        display: flex;
        justify-content: space-between;

        .features-list {
            width: 18%;
            display: flex;
            align-items: center;

            .feature-icon {
                color: #FFDB0D;
                font-size: 80px;
                margin-right: 20px;

                &.icon-bulb {
                    font-size: 65px;
                }

                &.icon-tools {
                    font-size: 60px;
                }
            }

            h5.feature-content {
                color: #fff;
                font-size: 22px !important;
                line-height: 1.8em;
            }
        }
    }
}

/* Mobile View */
@media only screen and (max-width: 768px) {
    .features-container {
        padding: 40px 0;
        /* Reduce padding for mobile */
    }

    .features-row {
        flex-direction: column;
        /* Stack features vertically */
        align-items: center;
        /* Center content on mobile */
        gap: 30px;
        /* Add spacing between stacked items */

        .features-list {
            width: 100% !important;
            /* Full-width for each feature item */
            justify-content: center;
            /* Center-align feature items */
            text-align: center;
            /* Center text on mobile */
            flex-direction: column;
            /* Stack icon and text vertically */
            margin-bottom: 20px;
            /* Add margin for better spacing */

            .feature-icon {
                margin-right: 0;
                /* Remove side margin */
                margin-bottom: 10px;
                /* Add bottom margin for spacing */
                font-size: 50px;
                /* Adjust icon size for mobile */

                &.icon-bulb {
                    font-size: 50px;
                    /* Adjust bulb icon size */
                }

                &.icon-tools {
                    font-size: 45px;
                    /* Adjust tools icon size */
                }
            }

            h5.feature-content {
                font-size: 18px !important;
                /* Reduce font size for mobile */
                line-height: 1.6em;
                /* Adjust line height */
            }
        }
    }
}