@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,600&family=Rajdhani:wght@500&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Oxanium:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font: 400 16px/1.7 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #202020;
  padding-top: 105px;
}

p {
  opacity: 0.7;
}

code {
  font-family: 'Roboto', sans-serif;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oxanium', sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  margin: 10px 0 30px;
  color: #202020;
}

h1 {
  color: #FFDB0D !important;
  position: relative;
  z-index: 11;

  span {
    font-size: 60px !important;
    display: block;
    color: #fff;
  }
}

h1,
h2 {
  font-size: 70px !important;
  line-height: 1.1em;
}


h4 {
  font-size: 40px !important;
  line-height: 1.3em;
}

h5 {
  font-size: 30px !important;
  margin: 0;
}

h6 {
  font-size: 16px !important;
  position: relative;
  margin: 0 auto -10px;
  font-family: 'Rajdhani', sans-serif;
  letter-spacing: 1px;
  width: fit-content;
  z-index: 9;
  text-align: center;

  &::after {
    display: block;
    content: "";
    position: absolute;
    left: -1px;
    bottom: 4px;
    right: -1px;
    background-color: #FFDB0D;
    height: 9px;
    z-index: -1;
  }
}

a {
  text-decoration: none !important;
  color: #fff;
  opacity: 0.8;
  transition: all 0.5s ease;

  &:hover,
  &:active,
  &:focus {
    color: #FFDB0D;
  }
}

.btn {
  padding: 16px 22px 16px !important;
  font-family: 'Oxanium', sans-serif !important;
  min-width: 180px !important;
  line-height: 20px !important;
  color: #fff !important;
  background: #202020 !important;
  font-size: 16px !important;
  transition: all 0.5s ease !important;
  cursor: pointer;

  &:hover {
    color: #202020 !important;
    background: #fff !important;
  }
}

.main-container {
  width: 90%;
  margin: 0 auto;
}

header {
  background: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0px 0px 6px #00000040;
  padding: 5px 0;

  .main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .brandLogo {
      opacity: 1;
    }

    img {
      max-width: 230px;
      width: 100%;
    }

    .header-menu {
      list-style: none;
      padding: 0;
      margin: 0 0 0 30px;

      li {
        display: inline-block;
        margin: 0 15px;

        a {
          color: #000;
          padding: 8px 10px;
          background: #fff;
          font-family: 'Oxanium', sans-serif;
          font-weight: 600;
          font-size: 18px;
          text-decoration: none;

          &:hover,
          &.active {
            background: #FFDB0D;
          }
        }
      }
    }
  }
}

#backToTop {
  color: #000;
  position: fixed;
  right: 30px;
  bottom: 30px;
  transition: all 0.2s ease;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 60px;
  height: 60px;
  background: #FFDB0D;
  border-radius: 50%;
  box-shadow: 0px 0px 6px #00000040;
  z-index: 11;

  &:hover {
    background: #fff;
    color: #000;
  }
}

.map {
  width: 100%;
  height: 500px;
  border: 0px;
  padding: 0px;
}

.map {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1);

  iframe {
    border: 0;
  }
}

/* Mobile View */
@media only screen and (max-width: 768px) {
  body {
    padding-top: 80px;
    /* Reduce the top padding for mobile */
    font-size: 14px;
    /* Slightly reduce base font size */
  }

  h1,
  h2 {
    font-size: 40px !important;
    /* Reduce large headings */
  }

  h4 {
    font-size: 30px !important;
    /* Reduce smaller headings */
  }

  h5 {
    font-size: 24px !important;
    /* Reduce font size for h5 */
  }

  h6 {
    font-size: 14px !important;
    width: 100%;
    /* Ensure h6 stays responsive */
  }

  p {
    font-size: 15px !important;
    justify-content: center !important;
    /* Reduce paragraph font size */
  }

  .main-container {
    width: 95%;
    /* Increase width to better use available space */
  }

  .banner-content {
    width: 100%;
    /* Make content full-width */
  }

  header .main-container {
    flex-direction: column;
    /* Stack header elements vertically */
    align-items: flex-start;
    padding: 0 20px;
    /* Add padding for mobile header */
  }

  header .header-menu {
    margin: 0 !important;
    display: flex;
    flex-wrap: wrap;
    /* Make header menu wrap */
    justify-content: space-around;
    /* Center header items */
    width: 100%;
  }

  header .header-menu li {
    margin: 5px 0;
    /* Add margin between menu items */
  }

  header img {
    max-width: 150px;
    /* Reduce the size of the logo */
  }

  .btn {
    padding: 12px 18px !important;
    /* Adjust button padding */
    font-size: 14px !important;
    /* Reduce button font size */
  }

  #backToTop {
    width: 50px;
    /* Reduce button size */
    height: 50px;
    font-size: 20px;
    /* Smaller font for back to top */
  }

  .map {
    height: 300px;
    /* Reduce map height */
  }
}