.contact-form-container {
    background: #FFDB0D;
}

.contact-form-container .contact-form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.contact-form-container .col-1 {
    width: 37%;
    padding-right: 100px;
    position: relative;
}

.contact-form-container .col-1 h2 {
    position: relative;
    z-index: 1;
}

.contact-form-container .col-1 .img-bulb {
    position: absolute;
    left: -10%;
    bottom: 0;
    z-index: 0;
}

.contact-form-container .col-2 {
    width: 40%;
    padding: 60px 100px 60px 0;
}

.contact-form-container .col-2 h4 {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: bold;
}

.contact-form-container .col-2 p {
    margin: 0 0 20px;
}

.contact-form-container .col-2 .form-content {
    width: 100%;
    font: 400 16px/1.7 'Poppins', sans-serif;
    margin-bottom: 15px;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
}

/* Mobile View */
@media only screen and (max-width: 768px) {
    .contact-form-container {
        width: 100% !important;
    }

    .contact-form-container .contact-form-row {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 60%;
        padding-left: none;
        width: 80% !important;
    }

    .contact-form-container .col-1,
    .contact-form-container .col-2 {
        width: auto !important;
        padding: 20px 0;
    }

    .contact-form-container .col-1 {
        padding-right: 0;
        text-align: center;
    }

    .contact-form-container .col-1 .img-bulb {
        left: 50%;
        transform: translateX(-50%);
        bottom: -50px;
        /* Adjust if necessary */
    }

    .contact-form-container .col-2 {
        padding: 20px;
    }

    .contact-form-container .col-2 .form-content {
        padding: 15px;
        font-size: 14px;
    }
}