/* CarouselBanner.scss */

/* Common styles for all banners */
/* Common styles for all banners */
.banner {
    padding-top: 5rem;
    position: relative;
    height: 60vh;
    width: 100%;
    overflow: hidden;
    /* Prevents image overflow */
    display: flex;
    align-items: center;
    /* Ensures content is vertically centered */
    justify-content: center;
    /* Centers the content horizontally */
}

.banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Dark overlay layer */
    filter: blur(10px);
    /* Apply blur to the layer */
    transition: all 0.5s ease;
    /* Smooth transition */
    z-index: 1;
    /* Keep the overlay above the image */
}

/* Ensure the image covers the entire banner area and doesn't get compressed */
.banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the container */
    object-position: center;
    /* Centers the image */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    /* Ensure image stays below the overlay */
}

/* Styling for the text */
.banner h1 {
    color: #FFDB0D;
    position: relative;
    z-index: 11;
    font-size: 70px;
    line-height: 1.1em;
    font-family: Oxanium, sans-serif;
    text-align: center;
    /* Center text horizontally */
    width: 100%;

    span {
        font-size: 40px !important;
        display: block;
        color: #fff;
    }
}

.banner p {
    font-size: 18px;
    color: #fff;
    text-align: center;
    /* Center text */
    margin-bottom: 50px;
}

/* Remove the blur and dark layer on hover */
.banner:hover::before {
    filter: blur(0);
    /* Remove the blur effect */
    background-color: rgba(0, 0, 0, 0);
    /* Remove the dark overlay */
}

/* Ensure that the carousel caption appears above everything */
.carousel-caption {
    position: relative;
    z-index: 2;
    /* Keep caption above the filter and image */
}

/* Media query for devices with a max-width of 768px (tablets and small screens) */
@media (max-width: 768px) {

    .banner {
        padding-top: 6rem !important;
        /* Reduce padding for mobile screens */
        background-attachment: scroll !important;
        /* Disable fixed background on mobile for better performance */
        background-position: 70% !important;

        height: 60vh;
        /* Adjust the height for smaller screens */

        h1 {
            font-size: 40px !important;
            /* Reduce heading size */

            span {
                font-size: 28px !important;
                /* Adjust size for subheading */
            }
        }

        p {
            font-size: 16px;
            /* Reduce paragraph font size */
            margin-bottom: 30px;
            /* Adjust spacing */
        }

        img {
            height: 100%;
        }
    }
}

/* Media query for devices with a max-width of 576px (mobile phones) */
@media (max-width: 576px) {
    .banner {
        padding-top: 6rem !important;
        /* Reduce padding for mobile screens */
        background-attachment: scroll !important;
        /* Disable fixed background on mobile for better performance */
        background-position: 70% !important;
        height: 60vh;
        /* Further reduce the height for mobile */

        h1 {
            font-size: 35px;
            /* Reduce heading size for mobile */

            span {
                font-size: 25px;
                /* Further reduce subheading size */
            }
        }

        p {
            font-size: 14px;
            /* Reduce paragraph font size further */
            margin-bottom: 20px;
            /* Adjust spacing */
        }

        img {
            height: 100%;
        }
    }
}

@media (max-width: 375px) {
    .banner {
        padding-top: 6rem !important;
        /* Reduce padding for mobile screens */
        background-attachment: scroll !important;
        /* Disable fixed background on mobile for better performance */
        background-position: 70% !important;
        height: 60vh;
        /* Further reduce the height for mobile */

        h1 {
            font-size: 32px !important;
            /* Reduce heading size for mobile */

            span {
                font-size: 20px !important;
                /* Further reduce subheading size */
            }
        }

        p {
            font-size: 14px;
            /* Reduce paragraph font size further */
            margin-bottom: 20px;
            /* Adjust spacing */
        }

        img {
            height: 100%;
        }
    }
}