.services-container {
    padding: 120px 0;

    h2 {
        margin-top: 12px;
        text-align: center;
    }

    h6 {
        text-align: center;
    }

    .services-row {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 70px;

        div[class^='col'] {
            width: 33%;
        }

        .col-2 {
            padding: 0 20px 0 30px;
        }

        .col-3 {
            text-align: right;
        }

        ul {
            list-style: none;

            li {
                display: flex;
                margin: 30px 0 30px;
                gap: 30px;

                h4 {
                    color: #FFDB0D;
                }
            }
        }
    }
}

/* Mobile View */
@media only screen and (max-width: 768px) {
    .services-container {
        padding: 2rem 0;
        /* Reduce padding for mobile */
    }

    .services-container h6 {
        font-size: 20px;
        /* Reduce padding for mobile */
    }

    .services-container h2 {
        padding: 0 7rem;
        padding-top: 2rem;
        /* Reduce padding for mobile */
    }

    .services-row {
        flex-direction: column;
        /* Stack columns vertically */
        margin-top: 0 !important;
        /* Reduce top margin */
        align-items: center;
        /* Center content on mobile */
        gap: 40px;
        /* Add some spacing between stacked columns */

        div[class^='col'] {
            width: 80% !important;
            /* Make columns full width */
        }

        .col-2 {
            padding: 0;
            /* Remove side padding for mobile */
        }

        .col-3 {
            padding: 10px;
            text-align: center;
            /* Center-align column content */
        }

        ul {
            padding: 0;
            /* Remove padding */
        }

        li {
            width: 100% !important;
            /* Stack items vertically */
            align-items: center;
            /* Center-align items */
            margin: 20px 0;
            /* Reduce margin between list items */
            gap: 20px;
            /* Reduce gap size */

            h4 {
                font-size: 22px;
                /* Slightly reduce font size for mobile */
                align-self: baseline;
            }

            h2 {
                padding: 0 7rem;
            }
        }
    }
}